<script>
import InfoPointForm from "@/views/menu/infoPoint/infoPointForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    infoDeskService: "infoDeskService",
    fileUploadService: "fileUploadService",
  }
})
export default class InfoPointEdit extends mixins(InfoPointForm) {
  formId = "edit-infoPoint-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.infoDeskService.update(this.model.id, {
        idHall: this.model.id,
        idEvent: this.model.idEvent,
        idSpeaker: this.model.idSpeaker,
        code: this.model.code,
        localizations: this.model.localizations,
      });
      this.$router.push("/infopoint/list");
      toastService.success(this.translations.success.infoDesk_edit);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.infoPoint_edit;
  }

  async afterCreate () {
    this.model = await this.infoDeskService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>
